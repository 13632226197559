'use client';

import { TestimonialsFragment } from '@queries/fragments/testimonials.fragment';
import { FragmentOf } from 'gql.tada';
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from './Carousel';
import TestimonialCard from './TestimonialCard';

interface Props {
	testimonials: FragmentOf<typeof TestimonialsFragment>[];
	className?: string;
}

export const Testimonials: React.FC<Props> = ({ testimonials, className }) => {
	if (!testimonials?.length) return null;

	return (
		<section className={className}>
			<Carousel
				opts={{ align: 'start' }}
				className="-mx-12 overflow-hidden px-12">
				<div className="mb-6 flex items-center justify-between">
					<h2 className="text-xl md:text-2xl">Recommendations</h2>
					{testimonials.length > 1 && (
						<div className="flex items-center space-x-2">
							<CarouselPrevious />
							<CarouselNext />
						</div>
					)}
				</div>

				<CarouselContent>
					{testimonials.map((item, index) => (
						<CarouselItem key={`carousel-${item.sys?.id}`}>
							<TestimonialCard item={item} />
						</CarouselItem>
					))}
				</CarouselContent>
			</Carousel>
		</section>
	);
};

export default Testimonials;
