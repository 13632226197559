'use client';
import HexRgba from '@lib/HexRgba';
import { ExperienceFragment } from '@queries/fragments/experience.fragment';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import {
	Briefcase02,
	ChevronDownDouble,
	ChevronUpDouble,
	Maximize01,
} from '@untitled-ui/icons-react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { FragmentOf } from 'gql.tada';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { Button } from './Button';
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from './Collapsible';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from './Dialog';
import Location from './Location';
import RichText from './RichText';
import { Wrap } from './Wrap';

interface Props {
	experience: FragmentOf<typeof ExperienceFragment>[];
	className?: string;
}

const dateClean = (date: string) => dayjs(date).format('MMM YYYY');

const ExperienceItem = ({
	item,
}: {
	item: FragmentOf<typeof ExperienceFragment>;
}) => {
	if (!item) return null;

	return (
		<article className="nice-transition group rounded-xl py-2 transition-all hover:bg-white md:py-4 dark:hover:bg-black">
			<Wrap
				key={item?.sys?.id}
				condition={item?.content !== null}
				wrapper={(children) => (
					<Dialog>
						<DialogTrigger
							asChild
							className="nice-transition flex w-full cursor-pointer bg-transparent text-left transition-all">
							{children}
						</DialogTrigger>
						<DialogContent>
							<DialogHeader>
								<aside
									className="not-prose mb-4 flex w-full flex-col gap-y-1 rounded-md bg-gray-100 p-3 text-sm md:gap-y-2 md:p-4 md:text-base"
									style={
										item?.company?.primaryColor
											? {
													backgroundColor: HexRgba(
														item?.company.primaryColor,
														10
													),
												}
											: {}
									}>
									<div className="flex items-center gap-x-4">
										{item?.company?.logo?.url ? (
											<div className="mb-2 flex size-10 items-center overflow-hidden rounded-md border bg-white p-2 md:size-12">
												<Image
													src={item.company.logo.url}
													alt={item.company.name || 'Company Logo'}
													width={64}
													height={64}
												/>
											</div>
										) : (
											<Briefcase02 className="size-5" />
										)}
										<div>
											<h2>{item?.company?.name}</h2>
											{item?.company?.website && (
												<Link
													href={item?.company?.website}
													target="_blank"
													className="hover:underline">
													{item?.company?.website}
												</Link>
											)}
										</div>
									</div>
									{item?.company?.summary && <p>{item?.company?.summary}</p>}
								</aside>
								<DialogTitle>
									{item.jobTitle}{' '}
									{item?.company?.name ? `at ${item.company.name}` : ''}
								</DialogTitle>
							</DialogHeader>
							<VisuallyHidden asChild>
								<DialogDescription>{item.jobTitle}</DialogDescription>
							</VisuallyHidden>
							<div className="space-y-4">
								<Location location={item.location} />
								<RichText content={item.content} />
							</div>
						</DialogContent>
					</Dialog>
				)}>
				<div className="flex w-full gap-5">
					<div
						className={clsx(
							'relative flex size-12 shrink-0 items-center justify-center overflow-hidden rounded-lg border border-gray-200 shadow-sm max-sm:mb-3 sm:mt-5',
							!item.company?.primaryColor && 'bg-white'
						)}
						style={
							item.company?.primaryColor
								? { backgroundColor: `#${item.company.primaryColor}` }
								: {}
						}>
						{item.company?.logo?.url ? (
							<Image
								src={item.company.logo.url}
								alt={item.company.name || 'Company Logo'}
								fill
							/>
						) : (
							<Briefcase02 className="size-5" />
						)}
					</div>

					<div className="w-full">
						<div className="space-y-1.5">
							<div className="flex justify-between text-xs text-gray-600 dark:text-gray-400">
								<span>
									{dateClean(item.dateFrom as string)} -{' '}
									{item.dateTo ? dateClean(item.dateTo as string) : 'Present'}
								</span>
								{item?.content && (
									<Maximize01 className="nice-transition size-4 transition-all group-hover:translate-x-1 group-hover:text-black dark:group-hover:text-white" />
								)}
							</div>
							<h3 className="text-lg font-semibold leading-tight">
								{item.jobTitle} at {item?.company?.name}
							</h3>
							<div className="text-xs font-medium text-gray-600 dark:text-gray-400">
								<span>{item.location}</span>
							</div>
						</div>
						<summary className="pt-2 text-base">{item.summary}</summary>
					</div>
				</div>
			</Wrap>
		</article>
	);
};

const ToggleButton = ({
	isOpen,
	setIsOpen,
}: {
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
}) => (
	<CollapsibleTrigger asChild>
		<div className="flex justify-center">
			<Button variant="outline" className="group/button">
				<span>{isOpen ? 'Hide Experience' : 'View All Experience'}</span>
				{isOpen ? (
					<ChevronUpDouble className="size-5 group-hover/button:animate-pulse" />
				) : (
					<ChevronDownDouble className="size-5 group-hover/button:animate-pulse" />
				)}
			</Button>
		</div>
	</CollapsibleTrigger>
);

export const Experience: React.FC<Props> = ({ experience, className }) => {
	if (!experience?.length) return null;

	const [isOpen, setIsOpen] = useState(false);
	const latestExperience = experience.slice(0, 3);
	const otherExperience = experience.slice(3);

	return (
		<section className={className}>
			<h2 className="text-xl md:text-2xl">My Experience</h2>
			<div className="space-y-1 py-4">
				{latestExperience.map((item, index) => (
					<ExperienceItem item={item} key={index} />
				))}
			</div>
			{otherExperience.length > 0 && (
				<Collapsible
					open={isOpen}
					onOpenChange={setIsOpen}
					className="space-y-2">
					{!isOpen && <ToggleButton isOpen={isOpen} setIsOpen={setIsOpen} />}
					<CollapsibleContent className="space-y-2">
						{otherExperience.map((item, index) => (
							<ExperienceItem item={item} key={index} />
						))}
					</CollapsibleContent>
					{isOpen && <ToggleButton isOpen={isOpen} setIsOpen={setIsOpen} />}
				</Collapsible>
			)}
		</section>
	);
};
