import { Globe01 } from '@untitled-ui/icons-react';

const Location: React.FC<{ location: string | null }> = ({ location }) => {
	// Show nothing if there is nothing to show
	if (!location) {
		return null;
	}
	return (
		<address>
			<p className="flex items-center justify-start space-x-1 text-lg not-italic">
				<Globe01 className="size-4" />
				<span>{location}</span>
			</p>
		</address>
	);
};
export default Location;
